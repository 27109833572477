


function Learn() {

    return (
      <div className="">
      </div>
    );
  
  }
  
  export default Learn;