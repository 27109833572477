


function BkForms() {

    return (
        <>
            <div className="form-1"></div>
            <div className="form-2"></div>
            <div className="form-3"></div>
            <div className="form-4"></div>
        </>
    );
  
  }
  
  export default BkForms;